import React, { useEffect, useState } from "react"
import ReactPaginate from "react-paginate"
import ArticlesBlog from "../../../components/organisms/ArticlesBlog"
import { anchorToElement } from "../../../helpers/anchorToElement"
import { dateToLocaleString } from "../../../helpers/dateToLocaleString"
import "./styles.scss"

const ITEMS_PER_PAGE = 9
const BlogPagination = ({ posts, setActivePage, activePage }) => {
  const [postsToBeShown, setPostsToBeShown] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const hasMoreThanOnePage = posts.length >= 10

  const onPageChange = event => {
    const newOffset = (event.selected * ITEMS_PER_PAGE) % posts.length
    anchorToElement("#blog-top")
    setItemOffset(newOffset)
    setActivePage(event.selected)
  }

  useEffect(() => {
    const endOffset = itemOffset + ITEMS_PER_PAGE
    setPostsToBeShown(posts.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(posts.length / ITEMS_PER_PAGE))
  }, [itemOffset, ITEMS_PER_PAGE, posts])

  useEffect(() => {
    if (activePage === 0) {
      setItemOffset(0)
    }
  }, [activePage])

  return (
    <div className="blog-pagination">
      <div className="blog-pagination__posts">
        {postsToBeShown &&
          postsToBeShown.map(post => (
            <div className="blog-pagination__posts--item">
              <ArticlesBlog
                icon={post.icon}
                title={post.title}
                date={dateToLocaleString(post.date)}
                category={post.category}
                shortDescription={post.shortDescription}
                url={post.url}
              />
            </div>
          ))}
      </div>
      {hasMoreThanOnePage && (
        <ReactPaginate
          nextLabel={<span id="buttonNext" />}
          previousLabel={<span id="buttonPrevious" />}
          pageCount={pageCount}
          onPageChange={onPageChange}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageClassName="blog-pagination__controllers--item"
          pageLinkClassName="page-link"
          previousClassName="blog-pagination__controllers--previous"
          nextClassName="blog-pagination__controllers--next"
          breakClassName="blog-pagination__controllers--item"
          breakLinkClassName="page-link"
          containerClassName="blog-pagination__controllers"
          activeClassName="active"
          renderOnZeroPageCount={null}
          forcePage={activePage}
        />
      )}
    </div>
  )
}

export default BlogPagination
