import { useEffect } from "react"

const useClickOutside = (ref, cb) => {
  useEffect(() => {
    const handleOutside = event => {
      if (!ref.current?.contains(event.target)) {
        cb()
      }
    }

    document.addEventListener("keyup", handleOutside)
    document.addEventListener("mousedown", handleOutside)

    return () => {
      document.removeEventListener("keyup", handleOutside)
      document.removeEventListener("mousedown", handleOutside)
    }
  }, [ref, cb])
}

export default useClickOutside
