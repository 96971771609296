import React from 'react'
import useStaticImage from '../../../hooks/useStaticImage'
import { GatsbyImage } from 'gatsby-plugin-image'
import './styles.scss'
import { Link } from 'gatsby'
import { formatSrcToGatsbyImage } from '../../../helpers/formatSrcToGatsbyImage'

const ArticlesBlog = ({ icon, title, category, date, shortDescription, url }) => {
  const [getImage] = useStaticImage()
  const firstWord = category?.toLowerCase().split(' ').pop()
  console.log('icon', icon)
  const getDescription = () => {
    return `${shortDescription.substring(0, 200)}...`
  }

  return (
    <section className="articles-blog">
      <div className="articles-blog__container">
        <div className="articles-blog__news">
          <ul>
            <li>
              <Link to={url}>
                <GatsbyImage
                  className={`articles-blog__news--img articles-blog__news--img-${firstWord}`}
                  objectFit="cover"
                  image={formatSrcToGatsbyImage(icon)}
                  alt={title}
                />
              </Link>
              <p>{date}</p>
              <p
                className={`articles-blog__news-title articles-blog__news--title-${firstWord}`}
              >
                {`${category?.toUpperCase()}_`}
              </p>
              <p>
                <Link to={url}>{title}</Link>
              </p>
              <p>{getDescription()}</p>
              <p>
                <Link to={url}>Ler mais</Link>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}
export default ArticlesBlog
