import React, { useState, useRef } from "react"
import useClickOutside from "../../../hooks/useClickOutside"
import "./styles.scss"

const DropDownSelection = ({
  options,
  onSelect,
  noMarginTop,
  setActivePage,
}) => {
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  const toggling = () => setIsOpen(!isOpen)

  const onOptionClicked = value => {
    setSelectedOption(value)
    setIsOpen(false)
    setActivePage(0)
  }

  useClickOutside(dropdownRef, () => isOpen && setIsOpen(false))

  return (
    <div ref={dropdownRef} className="drop-down-selection__container">
      <div
        style={{ marginTop: noMarginTop ? "0" : "60px" }}
        className="drop-down-selection__container-header"
        onClick={toggling}
      >
        <h3>{selectedOption || "Todas as categorias"}</h3>
        {isOpen ? (
          <div className="drop-down-selection__container-header-open" />
        ) : (
          <div className="drop-down-selection__container-header-closed" />
        )}
        {isOpen && (
          <div>
            <ul className="drop-down-selection__container-header-list">
              <>
                {options?.map(option => (
                  <li
                    className="drop-down-selection__container-header-list-item"
                    onClick={option => {
                      onOptionClicked(option.target.innerText.replace("_", ""))
                      onSelect(option.target.innerText.replace("_", ""))
                    }}
                    key={Math.random()}
                  >
                    <p>{(option += "_")}</p>
                  </li>
                ))}
                <li
                  className="drop-down-selection__container-header-list-item"
                  onClick={option => {
                    onOptionClicked(null)
                    onSelect(null)
                  }}
                  key={Math.random()}
                >
                  <p>Todas as categorias</p>
                </li>
              </>
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default DropDownSelection
