import React, { useState } from 'react'
import { useLocation } from '@reach/router'
import Layout from '../layout'
import Seo from '../../helpers/seo'
import { dateToLocaleString } from '../../helpers/dateToLocaleString'
import DropDownSelection from '../../components/molecules/DropDownSelection'
import NewsLetter from '../../components/organisms/NewsLetter'
import SectionContainer from '../../components/molecules/SectionContainer'
import Slogan from '../../components/molecules/Slogan'
import BlogPagination from './blog-pagination'
import './styles.scss'
import useContentfulPosts from '../../hooks/useContentfulPosts'
import Loader from '../../assets/images/blog/loader.gif'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { formatSrcToGatsbyImage } from '../../helpers/formatSrcToGatsbyImage'

const Blog = () => {
  const location = useLocation()
  const [selectedOption, setSelectedOption] = useState(null)
  const [activePage, setActivePage] = useState(0)
  const { posts } = useContentfulPosts()
  const blogPosts = posts.map((post) => ({
    ...post,
    url: `${location.origin}/blog/posts/${post.path}`,
  }))
  const duplicatedCategories = blogPosts.map((article) => article.category)
  const categories = [...new Set(duplicatedCategories)]

  const orderedPostsByDate = blogPosts
    .map((p) => {
      const [day, month, year] = p.date.split('/')
      const stringDate = `${month}/${day}/${year}`
      const timestamp = new Date(stringDate)

      return { ...p, date: timestamp }
    })
    .sort((a, b) => b.date - a.date)

  const postsFiltered =
    selectedOption === null
      ? orderedPostsByDate
      : orderedPostsByDate.filter((article) => article.category === selectedOption)

  const mainPostCategory = postsFiltered[0]?.category?.toLowerCase().split(' ')[0]

  const onOptionClicked = (value) => {
    setSelectedOption(value)
  }

  const getArrWithoutFirstItem = (arr) => {
    arr.shift()
    return arr
  }

  if (!blogPosts.length) {
    return (
      <div style={{ width: '100vw', height: '100vh' }}>
        <img
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '200px',
          }}
          src={Loader}
          alt="loader"
        />
      </div>
    )
  }

  return (
    <Layout page="Blog">
      <Slogan id="blog-top">
        <b>Blog</b>
      </Slogan>

      <SectionContainer>
        <div className="blog-principal__container-principal">
          {activePage === 0 && (
            <div className="blog-principal__container-principal-article">
              <ul>
                <li>
                  <Link to={postsFiltered[0].url}>
                    <GatsbyImage
                      className={`blog-principal__container-principal-article--img blog-principal__container-principal-article--img-${mainPostCategory}`}
                      image={formatSrcToGatsbyImage(postsFiltered[0].icon)}
                      alt={postsFiltered[0].title}
                    />
                  </Link>
                  <div>
                    <p>{dateToLocaleString(postsFiltered[0]?.date)}</p>
                    <p>
                      <a
                        style={{ cursor: 'initial', pointerEvents: 'none' }}
                        href="/"
                        className={`blog-principal__container-principal-article__category blog-principal__container-principal-article__category-${mainPostCategory}`}
                      >
                        {`${postsFiltered[0]?.category?.toUpperCase()}_`}
                      </a>
                    </p>
                    <p>
                      <Link to={postsFiltered[0].url}>{postsFiltered[0]?.title}</Link>
                    </p>
                    <p>
                      <Link to={postsFiltered[0]?.url}>Ler mais</Link>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          )}
          <div>
            <DropDownSelection
              noMarginTop={activePage !== 0}
              options={categories}
              onSelect={onOptionClicked}
              setActivePage={setActivePage}
            />
          </div>
        </div>
        <div className="blog-principal__container">
          <BlogPagination
            posts={getArrWithoutFirstItem(postsFiltered)}
            setActivePage={setActivePage}
            activePage={activePage}
          />
        </div>
      </SectionContainer>
      <NewsLetter />
    </Layout>
  )
}

export const Head = () => <Seo title="Blog" />

export default Blog
